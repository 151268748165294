import { helpers } from 'vuelidate/lib/validators';

export const minDate = param => {
  return value => {
    return !helpers.req(value) || new Date(value) >= new Date(param);
  };
};
export const maxDate = param => {
  return value => {
    return !helpers.req(value) || new Date(value) <= new Date(param);
  };
};

export const telefonszam = () => {
  return value => {
    var searchRegex = new RegExp(/^\+[0-9 -]{3,30}$/, 'im');
    return searchRegex.test(value + '');
  };
};
export const azonosito = () => {
  return value => {
    var searchRegex = new RegExp(/^[A-z0-9-_]+$/, 'i');
    return searchRegex.test(value + '');
  };
};
export const notSameAs = e => {
  return value => {
    return value == e;
  };
};

export const checkBoxValidator = () => {
  return value => {
    return helpers.req(value);
  };
};
